import {setFieldVisibility} from "../../../../../apps/KpModule/actions"

export const module_ = {
    object: 'Adhesion',
    name: 'Application',
    tKey: 'mTitle_adhesionApplication',
    newable: false,
    removable: false,
    objectIdentifier: 'mail',
    category: {
        path: 'Adhésion',
        icon: 'folder'
    },
    viewMap: {
        dt: [
            {path: 'mail'},
            {path: 'firstname'},
            {path: 'lastname'},
            {path: 'phone'},
            {path: 'organizationName'},
            {path: 'status', type: "status", fieldPath: ['id', 'name', 'style']},
            {path: 'createdAt', tKey: 'creationDate'},
        ],
        form: {
            fields: [
                {path: 'userSection', data: 'Contact', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(252, 185, 0)', padding: '10px 0'}},
                {path: 'mail', disabled: true},
                {path: 'civility', disabled: true},
                {path: 'firstname', disabled: true},
                {path: 'lastname', disabled: true},
                {path: 'phone', type: 'phoneNumber', disabled: true},
                {path: 'organizationPresident', disabled: true},
                {path: 'organizationSection', data: 'Association', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(252, 185, 0)', padding: '10px 0'}, disabled: true},
                {path: 'organizationName', tKey: 'name', disabled: true},
                {path: 'organizationAddress', tKey: 'address', disabled: true},
                {path: 'organizationAdditionalAddress', tKey: 'additionalAddress', disabled: true},
                {path: 'organizationZipCode', tKey: 'zipCode', disabled: true},
                {path: 'organizationCity', tKey: 'city', disabled: true},
                {path: 'organizationCountry', tKey: 'country', disabled: true},
                {path: 'rna', tKey: 'N° Registre National des Associations', disabled: true},
                {path: "organizationMail", tKey: 'Courriel principal', disabled: true},
                {path: "organizationPhone", tKey: 'Téléphone principal', disabled: true},
                {path: "sheltersNumber", tKey: 'Nombre de refuges', disabled: true},
                {path: 'contactID', tKey: 'Pièce d’identité du contact', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: "singleFile", disabled: true},
                'comments',
                {path: 'applicationNumber', hidden: true},
                {path: 'status', hidden: true},
                {path: 'buttons', hidden: true}
            ],
            onOpen: ({ store }) => {
                const state = store.getState()
                /*
                const hasShelter = state.edit.object.data?.hasShelter
                console.log(hasShelter)
                store.dispatch(setFieldVisibility(`e_shelterSection`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterName`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterAddress`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterAdditionalAddress`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterZipCode`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterCity`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterCountry`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_animalTypes`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterTypes`, !!hasShelter))
                 */
            }
        }
    }
}
