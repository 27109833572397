import {setFieldVisibility} from "../../../../../apps/KpModule/actions"

export const module_ = {
    object: 'Adhesion',
    name: 'File',
    tKey: 'mTitle_adhesionFile',
    newable: false,
    objectIdentifier: 'mail',
    category: {
        path: 'Adhésion',
        icon: 'folder'
    },
    viewMap: {
        dt: [
            {path: 'mail'},
            {path: 'firstname'},
            {path: 'lastname'},
            {path: 'status', type: "status", fieldPath: ['id', 'name', 'style']},
            {path: 'createdAt', tKey: 'creationDate'},
        ],
        form: {
            fields: [
                {path: 'userSection', data: 'Contact', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(252, 185, 0)', padding: '10px 0'}},
                {path: 'mail', disabled: true},
                {path: 'civility'},
                {path: 'firstname'},
                {path: 'lastname'},
                {path: 'phone', type: 'phoneNumber'},
                {path: 'organizationPresident'},
                {path: 'organizationSection', data: 'Association', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(252, 185, 0)', padding: '10px 0'}, disabled: true},
                {path: 'organizationName', tKey: 'name'},
                {path: 'organizationAddress', tKey: 'address'},
                {path: 'organizationAdditionalAddress', tKey: 'additionalAddress'},
                {path: 'organizationZipCode', tKey: 'zipCode'},
                {path: 'organizationCity', tKey: 'city'},
                {path: 'organizationCountry', tKey: 'country'},
                {path: 'rna', tKey: "N° Registre National des Associations"},
                {path: "organizationMail", tKey: "Courriel principal"},
                {path: "organizationPhone", tKey: "Téléphone principal"},
                {path: "sheltersNumber", tKey: "Nombre de refuges"},
                {path: 'contactID', tKey: 'Pièce d’identité du contact', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: "singleFile"},
                'comments',
                {path: 'applicationNumber', hidden: true},
                {path: 'status', hidden: true},
                {path: 'buttons', hidden: true}
            ],
            onOpen: ({ store }) => {
                /*
                const state = store.getState()
                const hasShelter = state.edit.object.data?.hasShelter
                console.log(hasShelter)
                store.dispatch(setFieldVisibility(`e_shelterSection`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterName`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterAddress`, !!hasShelter))
                //store.dispatch(setFieldVisibility(`e_shelterAdditionalAddress`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterZipCode`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterCity`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterCountry`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_animalTypes`, !!hasShelter))
                store.dispatch(setFieldVisibility(`e_shelterTypes`, !!hasShelter))
                 */
            }
        }
    },
    filters: ['byUser']
}
